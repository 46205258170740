import React from 'react'
import { Box, Typography } from '@mui/material'

import LineBreaks, {
  LineBreaksCoreProps,
} from '_core/components/atoms/typography/LineBreaks'

export interface HeroTextProps extends LineBreaksCoreProps {
  mainHeading: string
  smallHeading: string
}

const HeroText = ({
  mainHeading,
  smallHeading,
  keyId,
  breakpoint,
}: HeroTextProps) => {
  return (
    <>
      <Box typography="smallText" className="border" mb={1}>
        {smallHeading}
      </Box>
      <Typography component="h1" variant="h1Large">
        <LineBreaks
          keyId={keyId}
          text={mainHeading}
          breakpoint={breakpoint}
          alternateLines
        />
      </Typography>
    </>
  )
}

export default HeroText
