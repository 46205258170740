import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { styled } from '@mui/material'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'

import termStructure, { termProps } from '_core/constants/term-structure'

import Button from '_core/components/atoms/buttons/Button'
import Select from '_core/components/atoms/inputs/Select'

interface Props {
  keyId: string
}

interface termFilterProps {
  category: string
  term: string
}

interface FormValuesProps {
  departments: string[]
  regions: string[]
  levels: string[]
  locations: string[]
}

const StyledForm = styled(Form)(({ theme }) => ({
  display: 'block',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: theme.shape?.borderRadiusSmall,
    overflow: 'hidden',
  },
  ['> .quickSearch']: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '1rem',
      flex: 1,
    },
  },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  flex: 1,
  display: 'block',
  border: 'none',
  ['.MuiSelect-select']: {
    boxSizing: 'border-box',
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  [theme.breakpoints.up('lg')]: {
    height: '56px',
    borderRadius: '0 !important',
    display: 'inline-flex',
    ['.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input']: {
      borderRadius: '0 !important',
    },
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('lg')]: {
    borderRadius: theme.shape?.borderRadiusSmall,
    overflow: 'hidden',
    width: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'inline-flex',
    borderRadius: 0,
    overflow: 'hidden',
    width: '40%',
    height: 'auto',
  },
}))

const QuickSearch = ({ keyId }: Props) => {
  return (
    <>
      <Formik
        initialValues={{
          departments: [],
          regions: [],
          levels: [],
          locations: [],
        }}
        onSubmit={(values: FormValuesProps, { setSubmitting }) => {
          if (typeof window !== 'undefined') {
            window.gtag('event', 'quick_search', {
              action: 'Quick Search Form Submission',
            })
          }
          setTimeout(() => {
            let queryParams = '?'
            Object.keys(termStructure.primary).map((key, index) => {
              const value: string[] = values[key as keyof typeof values]
              const term: termProps =
                termStructure.primary[key as keyof typeof termStructure.primary]
              if (value.length > 0) {
                queryParams = `${queryParams}&${key}=${value}`
              }
            })
            setSubmitting(false)
            navigate(`/mandate-search${queryParams}`)
          }, 400)
        }}
      >
        {({ values }: FormValues) => {
          const [items, setItems] = useState({
            departments: [],
            regions: [],
            levels: [],
            locations: [],
          })
          const [updateCount, setUpdateCount] = useState(0)

          useEffect(() => {
            let termFilters: termFilterProps[] = []
            Object.keys(termStructure.primary).map((key, index) => {
              const value: string[] = values[key as keyof typeof values]
              const term: termProps =
                termStructure.primary[key as keyof typeof termStructure.primary]
              if (value.length > 0) {
                termFilters.push({
                  category: key,
                  term: value,
                })
              }
            })

            const fetchData = async () => {
              try {
                const result = await axios({
                  url: `${process.env.GATSBY_API_ENDPOINT}/site`,
                  method: 'post',
                  data: {
                    query: `
                      query($terms: [MandateTermFilter!]) {
                        mandateTerms(terms: $terms) {
                          departments {
                            name
                            value: slug
                          }
                          regions {
                            name
                            value: slug
                          }
                          levels {
                            name
                            value: slug
                          }
                          locations {
                            name
                            value: slug
                          }
                        }
                      }
                    `,
                    variables: {
                      terms: termFilters,
                    },
                  },
                })
                setItems(result.data.data.mandateTerms)
              } catch (error) {
                console.log('error:', error)
              }
            }
            fetchData()
          }, [updateCount])

          return (
            <StyledForm>
              {Object.keys(termStructure.primary).map((key, index) => {
                const term: termProps =
                  termStructure.primary[
                    key as keyof typeof termStructure.primary
                  ]

                return (
                  <Field key={`quickSearch-${key}-select`} name={key}>
                    {({ field, form: { setFieldValue } }) => {
                      const handleChange = (event) => {
                        setFieldValue(key, event.target.value)
                        setUpdateCount(updateCount + 1)
                      }

                      return (
                        <StyledSelect
                          className="quickSearch borderEnd"
                          placeholder={key}
                          items={items[key]}
                          fullWidth
                          inputProps={{
                            name: field.name,
                          }}
                          id={`${field.name}-${keyId}`}
                          name={field.name}
                          onBlur={field.onBlue}
                          value={field.value}
                          onChange={handleChange}
                        />
                      )
                    }}
                  </Field>
                )
              })}
              <StyledButton
                color="primary"
                variant="contained"
                type="submit"
                sizeVariant="xl"
              >
                Search
              </StyledButton>
            </StyledForm>
          )
        }}
      </Formik>
    </>
  )
}

export default QuickSearch
