import React, { memo } from 'react'
import { Box, Grid, Typography, styled } from '@mui/material'

interface Props {
  text: string
  icon: React.ReactNode
  className?: string
}

const Icon = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.outlineLight}`,
  borderRadius: '50%',
  margin: '0 auto',
  width: '60px',
  height: '60px',
  padding: '30px',
  boxSizing: 'content-box',
  svg: {
    display: 'block',
    width: '60px',
    height: '60px',
  },
  ['&.document']: {
    svg: {
      transform: 'translateX(5px)',
    },
  },
}))

const Benefit = ({ text, icon, className }: Props) => {
  return (
    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
      <Box p={{ xs: '0px', lg: 4 }}>
        <Icon className={className}>{icon}</Icon>
        <Typography
          component="h3"
          variant="h5"
          sx={{ fontSize: '2.2rem', mt: 2, color: 'common.white' }}
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  )
}

export default memo(Benefit)
