import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, Grid, Typography, styled } from '@mui/material'

import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import { sectionPadding } from 'public/styles/section-padding'
import HeadingDivider from 'public/components/atoms/dividers/HeadingDivider'

interface Props {
  heading: string
  image: IGatsbyImageData
  text: string
  buttonLabel: string
  buttonLinkSlug: string
  buttonLinkApiKey: string
}

const Overlap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '80px',
    marginLeft: '-80px',
    width: '100%',
    height: '100%',
  },
}))

const PanelContentGridItem = styled(Grid)(({ theme }) => ({
  ...sectionPadding(),
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

const ImageTextPanel = ({
  image,
  heading,
  text,
  buttonLinkSlug,
  buttonLinkApiKey,
  buttonLabel,
}: Props) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={5} xl={6} sx={{ my: { lg: 8 } }}>
        <Grid
          container
          spacing={0}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Grid item xs={12}>
            <GatsbyImage image={image} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={7} xl={6} sx={{ backgroundColor: 'lightGrey' }}>
        <Overlap bgcolor="lightGrey">
          <Grid
            container
            spacing={0}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <PanelContentGridItem item lg={10} xl={8}>
              <Container>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  {heading}
                </Typography>
                <HeadingDivider sx={{ mb: 3 }} />
                <Typography>{text}</Typography>
                <InternalButtonLink
                  slug={buttonLinkSlug}
                  apiKey={buttonLinkApiKey}
                  variant="outlined"
                  sizeVariant="xl"
                  colorVariant="outline-dark"
                  sx={{ mt: 3 }}
                >
                  {buttonLabel}
                </InternalButtonLink>
              </Container>
            </PanelContentGridItem>
          </Grid>
        </Overlap>
      </Grid>
    </Grid>
  )
}

export default memo(ImageTextPanel)
