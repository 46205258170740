import React, { useState } from 'react'
import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { TrendingFlat } from '@mui/icons-material'

import Button, { ButtonProps } from '_core/components/atoms/buttons/Button'
import Flex from '_core/components/atoms/layout/Flex'

import BackgroundVideo from 'public/components/molecules/media/BackgroundVideo'
import HeroText, {
  HeroTextProps,
} from 'public/components/molecules/sections/home-screen/HeroText'
import Screens from 'public/components/molecules/sections/home-screen/Screens'
import QuickSearch from 'public/components/molecules/sections/home-screen/QuickSearch'

interface Props extends HeroTextProps {
  startButtonLabel: string
  searchButtonLabel: string
}

interface StartButtonProps extends Omit<ButtonProps, 'onClick'> {
  showQuickSearch?: Function
}

const StartButton = ({ showQuickSearch, ...props }: StartButtonProps) => (
  <Button
    variant="outlined"
    colorVariant="outline-light"
    onClick={showQuickSearch}
    endIcon={<TrendingFlat fontSize="inherit" />}
    {...props}
  >
    Get Started
  </Button>
)

const HomeScreen = ({
  keyId,
  smallHeading,
  mainHeading,
  breakpoint,
  startButtonLabel,
  searchButtonLabel,
}: Props) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const [quickSearch, setQuickSearch] = useState(false)

  const showQuickSearch = () => {
    setQuickSearch(true)
    if (typeof window !== 'undefined') {
      window.gtag('event', 'get_Started_button_clicked', {
        action: 'Get Started Button Pressed',
      })
    }
  }

  return (
    <Box
      position="relative"
      bgcolor="secondary.main"
      color="common.white"
      width="100%"
    >
      <BackgroundVideo url="https://deltaexec-videos.s3.eu-west-2.amazonaws.com/004-final-1080p-2-3mbps.mp4" />

      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Flex
          column
          center
          full
          sx={{
            py: 10,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          {/* Mobile */}
          <Box sx={{ display: { xs: 'block', lg: 'none' } }} width="100%">
            <Screens
              leftScreen={
                <>
                  <Container
                    maxWidth="xs"
                    sx={{
                      m: '0 auto !important',
                      p: '0 !important',
                    }}
                  >
                    <HeroText
                      mainHeading={mainHeading}
                      smallHeading={smallHeading}
                      breakpoint="xs"
                      keyId={keyId}
                    />
                  </Container>
                  <StartButton
                    showQuickSearch={showQuickSearch}
                    sizeVariant="xl"
                    sx={{ mt: 5 }}
                  >
                    {startButtonLabel}
                  </StartButton>
                </>
              }
              rightScreen={<QuickSearch keyId="mobile" />}
              quickSearch={quickSearch}
            />
          </Box>

          {/* Desktop */}
          {useMediaQuery(theme.breakpoints.up('lg')) && (
            <Box
              sx={{
                display: { xs: 'none', lg: 'block' },
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Container fixed maxWidth="lg">
                <HeroText
                  mainHeading={mainHeading}
                  smallHeading={smallHeading}
                  breakpoint={breakpoint}
                  keyId={keyId}
                />
              </Container>

              <Screens
                wrapperProps={{ mt: 5 }}
                leftScreen={
                  <StartButton
                    showQuickSearch={showQuickSearch}
                    sizeVariant="xxl"
                  >
                    {startButtonLabel}
                  </StartButton>
                }
                rightScreen={<QuickSearch keyId="desktop" />}
                quickSearch={quickSearch}
              />
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default HomeScreen
