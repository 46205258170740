import React from 'react'
import { styled } from '@mui/material'

import Vignette from 'public/components/atoms/media/Vignette'
import BackgroundPlx from 'public/components/atoms/plx/BackgroundPlx'

interface Props {
  url: string
}

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  zIndex: -100,
  background: theme.palette.secondary.main,
}))

const Video = styled('video')({
  zIndex: -100,
  position: 'relative',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

const BackgroundVideo = ({ url }: Props) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <BackgroundPlx parallaxData={parallaxData}>
      <Container>
        <Video autoPlay muted loop playsInline>
          <source src={url} type="video/mp4" />
        </Video>
        <Vignette />
      </Container>
    </BackgroundPlx>
  )
}

export default BackgroundVideo
