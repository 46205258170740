import React, { memo } from 'react'
import { Box, BoxProps, Container, styled } from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'

interface Props {
  leftScreen: React.ReactNode
  rightScreen: React.ReactNode
  quickSearch?: boolean
  wrapperProps?: BoxProps
}

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
}))

const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '200%',
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
  }),
  ['&.open']: {
    transform: 'translateX(-50%)',
  },
}))

const Screen = styled(Flex)({
  textAlign: 'center',
  flex: 1,
})

const Screens = ({
  leftScreen,
  rightScreen,
  quickSearch,
  wrapperProps,
}: Props) => {
  let openClass = ''
  if (quickSearch) {
    openClass = 'open'
  }

  return (
    <Wrapper {...wrapperProps}>
      <Inner className={openClass}>
        <Screen center>
          <Container fixed maxWidth="xl">
            {leftScreen}
          </Container>
        </Screen>
        <Screen center>
          <Container fixed maxWidth="xl">
            <Flex full center>
              {rightScreen}
            </Flex>
          </Container>
        </Screen>
      </Inner>
    </Wrapper>
  )
}

export default memo(Screens)
