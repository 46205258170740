import React, { memo } from 'react'
import { styled } from '@mui/material'

const StyledVignette = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundImage:
    'linear-gradient( 180deg, rgba(29, 36, 51, 0.4) -50%, rgba(29, 36, 51, 0.85) 100%)',
})

const Vignette = () => {
  return <StyledVignette />
}

export default memo(Vignette)
