import React, { memo } from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import { Box, Container, Grid, styled, useTheme } from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import LineBreaks from '_core/components/atoms/typography/LineBreaks'
import Section from '_core/components/atoms/layout/Section'

import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import BackgroundPlx from 'public/components/atoms/plx/BackgroundPlx'
import Benefit from 'public/components/molecules/sections/account-benefits/AccountBenefit'
import Document from 'public/images/icons/benefits/document.svg'
import Graph from 'public/images/icons/benefits/graph.svg'
import Comm from 'public/images/icons/benefits/comm.svg'

const ImageContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  ['.gatsby-image-wrapper']: {
    backgroundColor: theme.palette.textDark,
    width: '100% !important',
    height: '100% !important',
  },
}))
const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  backgroundColor: theme.palette.textDark,
  width: '100% !important',
  height: '100% !important',
  opacity: 0.14,
}))

interface Props {
  smallHeading: string
  mainHeading: string
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  benefits: Queries.DatoCmsBenefit[]
}

const AccountBenefits = ({
  smallHeading,
  mainHeading,
  imageLandscape,
  imagePortrait,
  benefits,
}: Props) => {
  const theme = useTheme()

  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: -50,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <Section
      bgcolor="secondary.main"
      color="common.white"
      position="relative"
      vSpace
    >
      <BackgroundPlx parallaxData={parallaxData}>
        <ImageContainer>
          <StyledGatsbyImage image={images} alt="" />
        </ImageContainer>
      </BackgroundPlx>
      <Flex
        center
        sx={{
          minHeight: '100vh',
          position: 'relative',
          zIndex: 10,
          py: { xs: 6, md: 0 },
        }}
      >
        <Container maxWidth="lg">
          <SectionHeading
            smallHeading={smallHeading}
            heading={
              <LineBreaks
                text={mainHeading}
                keyId="benefitsHeading"
                breakpoint="xs"
              />
            }
            boxProps={{
              position: 'relative',
            }}
          />

          <Box py={{ xs: 2, md: '0px' }}>
            <Grid container spacing={6}>
              <Benefit
                text={benefits[0].benefit}
                icon={<Document />}
                className="document"
              />
              <Benefit text={benefits[1].benefit} icon={<Graph />} />
              <Benefit text={benefits[2].benefit} icon={<Comm />} />
            </Grid>
          </Box>
          <Flex center pt={5}>
            <InternalButtonLink
              slug="account/sign-up"
              variant="contained"
              color="primary"
              sizeVariant="xl"
              sx={{
                minWidth: '200px !important',
              }}
              onClick={(e) => {
                if (typeof window !== 'undefined') {
                  window.gtag('event', 'account_signup_button_clicked', {
                    action:
                      'Sign Up Button Pressed From Account Benefits Section',
                  })
                }
              }}
            >
              Sign Up
            </InternalButtonLink>
          </Flex>
        </Container>
      </Flex>
    </Section>
  )
}

export default memo(AccountBenefits)
